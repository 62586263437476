"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileMarkers = void 0;
const react_google_maps_1 = require("@vis.gl/react-google-maps");
const react_1 = __importStar(require("react"));
const react_redux_1 = require("react-redux");
const hooks_1 = require("../../../store/hooks");
const markersLoadingSlice_1 = require("../../../store/markersLoadingSlice");
const mobileStreamSelectors_1 = require("../../../store/mobileStreamSelectors");
const thresholdSlice_1 = require("../../../store/thresholdSlice");
const mapParamsHandler_1 = require("../../../utils/mapParamsHandler");
const thresholdColors_1 = require("../../../utils/thresholdColors");
const SessionDotMarker_1 = require("./SessionDotMarker/SessionDotMarker");
const SessionFullMarker_1 = require("./SessionFullMarker/SessionFullMarker");
const MobileMarkers = ({ sessions, onMarkerClick, selectedStreamId, pulsatingSessionId, }) => {
    const DISTANCE_THRESHOLD = 21;
    const ZOOM_FOR_SELECTED_SESSION = 16;
    // Latitude adjustment constants
    const LAT_DIFF_SMALL = 0.00001;
    const LAT_DIFF_MEDIUM = 0.0001;
    const LAT_ADJUST_SMALL = 0.005;
    const map = (0, react_google_maps_1.useMap)();
    const dispatch = (0, hooks_1.useAppDispatch)();
    const thresholds = (0, react_redux_1.useSelector)(thresholdSlice_1.selectThresholds);
    const { unitSymbol } = (0, mapParamsHandler_1.useMapParams)();
    const mobileStreamData = (0, react_redux_1.useSelector)(mobileStreamSelectors_1.selectMobileStreamData);
    const mobileStreamStatus = (0, react_redux_1.useSelector)(mobileStreamSelectors_1.selectMobileStreamStatus);
    const [markers, setMarkers] = (0, react_1.useState)({});
    const [selectedMarkerKey, setSelectedMarkerKey] = (0, react_1.useState)(null);
    const markersCount = Object.values(markers).filter((marker) => marker !== null).length;
    (0, react_1.useEffect)(() => {
        if (selectedStreamId && mobileStreamStatus !== "PENDING" /* StatusEnum.Pending */) {
            const { minLatitude, maxLatitude, minLongitude, maxLongitude } = mobileStreamData;
            if (minLatitude && maxLatitude && minLongitude && maxLongitude) {
                centerMapOnBounds(minLatitude, maxLatitude, minLongitude, maxLongitude);
            }
        }
        if (selectedStreamId === null) {
            setSelectedMarkerKey(null);
        }
    }, [selectedStreamId, mobileStreamData, mobileStreamStatus]);
    (0, react_1.useEffect)(() => {
        if (!selectedStreamId) {
            dispatch((0, markersLoadingSlice_1.setMarkersLoading)(true));
        }
    }, [dispatch, sessions.length]);
    (0, react_1.useEffect)(() => {
        if (!selectedStreamId && markersCount >= sessions.length) {
            dispatch((0, markersLoadingSlice_1.setMarkersLoading)(false));
        }
    }, [dispatch, markersCount, sessions.length]);
    const areMarkersTooClose = (marker1, marker2) => {
        var _a;
        if (!map)
            return false;
        const zoom = (_a = map.getZoom()) !== null && _a !== void 0 ? _a : 0;
        const latDiff = marker1.lat - marker2.lat;
        const lngDiff = marker1.lng - marker2.lng;
        const distance = Math.sqrt(latDiff * latDiff + lngDiff * lngDiff);
        // Convert distance to pixels based on the current zoom level
        const pixelSize = Math.pow(2, -zoom);
        const distanceInPixels = distance / pixelSize;
        return distanceInPixels < DISTANCE_THRESHOLD;
    };
    const calculateLatitudeDiff = (minLatitude, maxLatitude) => {
        return maxLatitude - minLatitude;
    };
    const adjustLatitude = (minLatitude, maxLatitude) => {
        const latDiff = calculateLatitudeDiff(minLatitude, maxLatitude);
        if (latDiff >= 0 && latDiff < LAT_DIFF_SMALL) {
            return minLatitude - LAT_ADJUST_SMALL;
        }
        else if (latDiff >= LAT_DIFF_SMALL && latDiff < LAT_DIFF_MEDIUM) {
            return minLatitude - latDiff * 2;
        }
        else {
            return minLatitude - latDiff;
        }
    };
    const calculateBounds = (maxLatitude, minLongitude, maxLongitude, adjustedLat) => {
        return [
            { lat: maxLatitude, lng: maxLongitude },
            { lat: adjustedLat, lng: minLongitude },
        ];
    };
    const centerMapOnBounds = (minLatitude, maxLatitude, minLongitude, maxLongitude) => {
        if (map && !selectedMarkerKey) {
            const latDiff = calculateLatitudeDiff(minLatitude, maxLatitude);
            const lngDiff = maxLongitude - minLongitude;
            if (latDiff < LAT_DIFF_SMALL && lngDiff < LAT_DIFF_SMALL) {
                const centerLat = (maxLatitude + minLatitude) / 2;
                const centerLng = (maxLongitude + minLongitude) / 2;
                map.setCenter({ lat: centerLat, lng: centerLng });
                map.setZoom(ZOOM_FOR_SELECTED_SESSION);
            }
            else {
                const adjustedLat = adjustLatitude(minLatitude, maxLatitude);
                const bounds = calculateBounds(maxLatitude, minLongitude, maxLongitude, adjustedLat);
                const googleBounds = new google.maps.LatLngBounds();
                bounds.forEach((coord) => googleBounds.extend(new google.maps.LatLng(coord.lat, coord.lng)));
                map.fitBounds(googleBounds);
                if (latDiff === 0) {
                    map.setZoom(ZOOM_FOR_SELECTED_SESSION);
                }
            }
            setSelectedMarkerKey(null);
        }
    };
    const centerMapOnMarker = (position) => {
        const { lat, lng } = position;
        if (map && !selectedMarkerKey) {
            map.setCenter({ lat, lng });
            map.setZoom(ZOOM_FOR_SELECTED_SESSION);
        }
        setSelectedMarkerKey(null);
    };
    const renderMarkerContent = (session, isSelected) => {
        const isOverlapping = sessions.some((otherSession) => otherSession.point.streamId !== session.point.streamId &&
            areMarkersTooClose(session.point, otherSession.point));
        if (isOverlapping) {
            return (react_1.default.createElement(SessionDotMarker_1.SessionDotMarker, { color: (0, thresholdColors_1.getColorForValue)(thresholds, session.lastMeasurementValue), shouldPulse: session.id === pulsatingSessionId, onClick: () => {
                    onMarkerClick(Number(session.point.streamId), Number(session.id));
                    centerMapOnMarker(session.point);
                } }));
        }
        return (react_1.default.createElement(SessionFullMarker_1.SessionFullMarker, { color: (0, thresholdColors_1.getColorForValue)(thresholds, session.lastMeasurementValue), value: `${Math.round(session.lastMeasurementValue)} ${unitSymbol}`, isSelected: isSelected, shouldPulse: session.id === pulsatingSessionId, onClick: () => {
                onMarkerClick(Number(session.point.streamId), Number(session.id));
                centerMapOnMarker(session.point);
            } }));
    };
    return (react_1.default.createElement(react_1.default.Fragment, null, sessions.map((session) => (react_1.default.createElement(react_google_maps_1.AdvancedMarker, { position: session.point, key: session.point.streamId, zIndex: 1000, ref: (marker) => {
            if (marker && !markers[session.point.streamId]) {
                setMarkers((prev) => (Object.assign(Object.assign({}, prev), { [session.point.streamId]: marker })));
            }
        } }, renderMarkerContent(session, session.point.streamId === (selectedStreamId === null || selectedStreamId === void 0 ? void 0 : selectedStreamId.toString())))))));
};
exports.MobileMarkers = MobileMarkers;
