"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFixedMarkersRenderer = void 0;
const colors_1 = require("../../../assets/styles/colors");
const createMarkerIcon_1 = require("./createMarkerIcon");
const createFixedMarkersRenderer = ({ thresholds, updateClusterStyle, clusterElementsRef, }) => ({
    render: (cluster) => {
        // Accept the entire Cluster object
        const { count, position, markers = [] } = cluster;
        const customMarkers = markers;
        const sum = customMarkers.reduce((acc, marker) => acc + Number(marker.get("value") || 0), 0);
        const average = sum / customMarkers.length;
        let styleIndex = 0;
        if (average < thresholds.low)
            styleIndex = 0;
        else if (average <= thresholds.middle)
            styleIndex = 1;
        else if (average <= thresholds.high)
            styleIndex = 2;
        else
            styleIndex = 3;
        const color = [colors_1.green, colors_1.yellow, colors_1.orange, colors_1.red][styleIndex];
        const clusterIcon = (0, createMarkerIcon_1.createClusterIcon)(color, false);
        const clusterMarker = new google.maps.Marker({
            position,
            icon: clusterIcon,
            zIndex: 1,
        });
        // Apply initial styles based on thresholds
        updateClusterStyle(clusterMarker, customMarkers, thresholds);
        // Store the reference to the cluster Marker
        clusterElementsRef.current.set(cluster, clusterMarker);
        // Add click listener to the cluster marker
        clusterMarker.addListener("click", () => {
            const clusterData = { count, position, markers };
            const event = new CustomEvent("cluster-click", { detail: clusterData });
            window.dispatchEvent(event);
        });
        return clusterMarker;
    },
});
exports.createFixedMarkersRenderer = createFixedMarkersRenderer;
