"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeeMoreSpan = exports.SeeMoreButton = exports.SectionButtonsContainer = exports.SectionButton = exports.ModalContent = exports.MobileSessionFilters = exports.MobileCustomParameters = exports.MinorShowSessionsButton = exports.InputWrapper = exports.Input = exports.InfoPopup = exports.InfoIcon = exports.InfoButton = exports.Info = exports.IndoorToggleWrapper = exports.IndoorToggleInfoWrapper = exports.IndoorToggleInfoText = exports.IndoorToggleHeading = exports.IndoorTab = exports.IndoorFilterWrapper = exports.IconWrapper = exports.Hr = exports.HeaderTitle = exports.Header = exports.GrayButton = exports.FiltersOptionsWrapper = exports.FiltersOptionHeading = exports.FiltersOptionButton = exports.DormantYearPickerWrapper = exports.DesktopCustomParameters = exports.Description = exports.CustomParameterWrapper = exports.CustomParametersListWrapper = exports.CustomParametersInput = exports.CustomParameterSearch = exports.CustomParameterList = exports.CustomParameterItem = exports.CustomParameter = exports.CrowdMapToggleText = exports.CrowdMapToggleOnOff = exports.CrowdMapGridSizeWrapper = exports.CloseSelectedItemButton = exports.ChevronIcon = exports.ChevronBackButton = exports.ButtonsWrapper = exports.ButtonSpan = exports.BasicParameterWrapper = exports.BasicParameterButtonsWrapper = exports.BasicParameterButton = exports.BackButton = void 0;
exports.YearPickerWrapper = exports.Wrapper = exports.ToggleWrapper = exports.ToggleSettingsContainer = exports.Tab = exports.SuggestionList = exports.Suggestion = exports.SingleFilterWrapper = exports.ShowSessionsButton = exports.SessionToggleWrapper = exports.SessionFilters = exports.SelectedOptionHeadingWrapper = exports.SelectedOptionHeading = exports.SelectedOptionButton = exports.SelectedOption = exports.SelectedItemTile = exports.SelectedItemsWrapper = exports.SelectedItem = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("../../assets/styles/colors");
const media_1 = require("../../utils/media");
const Button_style_1 = require("../Button/Button.style");
const LocationSearch_style_1 = require("../LocationSearch/LocationSearch.style");
const Legend_style_1 = require("../Map/Legend/Legend.style");
const Popups_style_1 = require("../Popups/Popups.style");
const Typography_1 = require("../Typography");
const fadeIn = (0, styled_components_1.keyframes) `
  from {
    opacity: 0;
    transform: translateY(-1.5rem);
    max-height: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
    max-height: 33rem;
  }
`;
const fadeOut = (0, styled_components_1.keyframes) `
  from {
    opacity: 1;
    transform: translateY(0);
    max-height: 33rem;
  }
  to {
    opacity: 0;
    transform: translateY(-1.5rem);
    max-height: 0;
  }
`;
const SessionFilters = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  padding: 0 0.9rem 1.6rem 0.9rem;
  margin: 0.8rem 0 0 2rem;
  width: 30rem;
  border-radius: 10px;
  background-color: ${colors_1.white};
`;
exports.SessionFilters = SessionFilters;
const Wrapper = styled_components_1.default.div `
  position: relative;
`;
exports.Wrapper = Wrapper;
const SingleFilterWrapper = styled_components_1.default.div `
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.$noMarginTop ? 0 : "1.6rem")};
  width: 100%;
`;
exports.SingleFilterWrapper = SingleFilterWrapper;
const SessionToggleWrapper = styled_components_1.default.div `
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  height: 4.2rem;
  width: 100%;
`;
exports.SessionToggleWrapper = SessionToggleWrapper;
const Tab = styled_components_1.default.button `
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1rem 3rem;
  background-color: ${(props) => (props.$isActive ? colors_1.acBlue : colors_1.gray100)};
  border: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${(props) => (props.$isActive ? colors_1.white : colors_1.gray300)};
  cursor: pointer;

  &:first-child {
    border-radius: 10px 0 0 10px;
  }

  &:nth-child(2) {
    border-radius: 0 10px 10px 0;
  }
`;
exports.Tab = Tab;
const IconWrapper = styled_components_1.default.div `
  margin-left: 0.8rem;
  background-color: ${(props) => (props.$isActive ? colors_1.white : colors_1.gray300)};
  mask: url(${(props) => props.$src});
  mask-size: 100% 100%;
  width: 2rem;
  height: 2rem;
`;
exports.IconWrapper = IconWrapper;
const MobileSessionFilters = (0, styled_components_1.default)(SessionFilters) `
  display: fixed;
  bottom: 0;
  top: 0;
  z-index: 6;
  margin: 0;
  width: 100%;
  height: 100%;
`;
exports.MobileSessionFilters = MobileSessionFilters;
const ModalContent = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background-color: ${colors_1.white};
  height: 10.5rem;
`;
exports.ModalContent = ModalContent;
const Header = styled_components_1.default.div `
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
`;
exports.Header = Header;
const HeaderTitle = styled_components_1.default.span `
  font-size: 1.6rem;
  margin-left: 1.2rem;

  &::first-letter {
    text-transform: uppercase;
  }
`;
exports.HeaderTitle = HeaderTitle;
const ShowSessionsButton = (0, styled_components_1.default)(Button_style_1.Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: ${colors_1.acBlue};
  color: ${colors_1.white};
  font-size: 1.4rem;
  text-transform: uppercase;
`;
exports.ShowSessionsButton = ShowSessionsButton;
const InputWrapper = styled_components_1.default.div `
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;
exports.InputWrapper = InputWrapper;
const Input = styled_components_1.default.input `
  border: 1px solid ${colors_1.gray200};
  border-radius: 5px;
  width: 100%;
  font-size: 1.6rem;
  padding: 1.2rem 1.6rem;

  &::placeholder {
    color: ${colors_1.gray600};
    text-transform: uppercase;
  }

  @media ${media_1.media.desktop} {
    font-size: 1.4rem;
  }
`;
exports.Input = Input;
const SuggestionList = styled_components_1.default.ul `
  max-height: 30rem;
  top: 6rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0.5rem;
  display: ${(p) => (p.$displaySearchResults ? "block" : "none")};
  position: absolute;
  background-color: ${colors_1.white};
  width: 100%;
  border: 1px solid ${colors_1.gray200};
  border-radius: 5px;
  z-index: 1;
`;
exports.SuggestionList = SuggestionList;
const Suggestion = styled_components_1.default.li `
  font-size: 1.6rem;
  cursor: pointer;
  padding: 0.5rem;
  list-style: none;
`;
exports.Suggestion = Suggestion;
const SelectedItemsWrapper = styled_components_1.default.div `
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`;
exports.SelectedItemsWrapper = SelectedItemsWrapper;
const SelectedItemTile = styled_components_1.default.div `
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid ${colors_1.gray200};
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem 0;
`;
exports.SelectedItemTile = SelectedItemTile;
const SelectedItem = styled_components_1.default.span `
  font-size: 1.4rem;
  margin-right: 0.5rem;
  color: ${colors_1.gray400};
`;
exports.SelectedItem = SelectedItem;
const CloseSelectedItemButton = (0, styled_components_1.default)(Legend_style_1.CloseButton) `
  width: 1rem;
  height: 1rem;
`;
exports.CloseSelectedItemButton = CloseSelectedItemButton;
const InfoButton = styled_components_1.default.button `
  border: none;
  background-color: transparent;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.5rem;
`;
exports.InfoButton = InfoButton;
const InfoIcon = styled_components_1.default.img `
  width: 100%;
  height: 100%;
`;
exports.InfoIcon = InfoIcon;
const Info = styled_components_1.default.span `
  font-size: 1.4rem;
  color: ${colors_1.gray400};
`;
exports.Info = Info;
const InfoPopup = (0, styled_components_1.default)(Popups_style_1.SmallPopup) `
  &-content {
    border: 1px solid ${colors_1.gray200};
    width: 250px;
  }
`;
exports.InfoPopup = InfoPopup;
const CrowdMapGridSizeWrapper = styled_components_1.default.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 0.4rem;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${colors_1.gray300};
  letter-spacing: 0.01em;

  overflow: hidden;
  max-height: ${({ $isVisible }) => ($isVisible ? "33rem" : "0")};
  animation: ${({ $isVisible }) => ($isVisible ? fadeIn : fadeOut)} 0.5s ease
    forwards;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transform: ${({ $isVisible }) => $isVisible ? "translateY(0)" : "translateY(-1.5rem)"};
  transition: max-height 1s ease, opacity 0.5s ease, transform 0.5s ease;
`;
exports.CrowdMapGridSizeWrapper = CrowdMapGridSizeWrapper;
const SectionButtonsContainer = styled_components_1.default.div `
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  grid-gap: 0.8rem;
`;
exports.SectionButtonsContainer = SectionButtonsContainer;
const SectionButton = (0, styled_components_1.default)(Button_style_1.Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  background-color: ${(props) => (props.$isActive ? colors_1.acBlue : colors_1.gray100)};
  color: ${(props) => (props.$isActive ? colors_1.white : colors_1.gray300)};
  min-width: 3.8rem;
  transition: background-color 0.4s ease, color 0.3s ease;
  height: 25px;
`;
exports.SectionButton = SectionButton;
const ToggleSettingsContainer = styled_components_1.default.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: ${({ $isActive }) => ($isActive ? "0.8rem" : "0")};
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors_1.gray200};
  min-height: 4.2rem;
  width: 100%;
  padding: 1.1rem 1.6rem;
  border-radius: 0.5rem;
`;
exports.ToggleSettingsContainer = ToggleSettingsContainer;
const ToggleWrapper = styled_components_1.default.div `
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.8rem;
  align-items: center;
  font-size: 1.4rem;
  color: ${colors_1.gray600};
  width: 100%;
`;
exports.ToggleWrapper = ToggleWrapper;
const CrowdMapToggleText = styled_components_1.default.span `
  &::first-letter {
    text-transform: uppercase;
  }
`;
exports.CrowdMapToggleText = CrowdMapToggleText;
const CrowdMapToggleOnOff = styled_components_1.default.span `
  text-transform: uppercase;
  font-weight: 700;
`;
exports.CrowdMapToggleOnOff = CrowdMapToggleOnOff;
const SelectedOptionButton = (0, styled_components_1.default)(Button_style_1.Button) `
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 1.6rem;
  background-color: ${(props) => props.$isActive && colors_1.acBlueTransparent};
  border-color: ${(props) => props.$isActive && colors_1.acBlue};
`;
exports.SelectedOptionButton = SelectedOptionButton;
const SelectedOptionHeadingWrapper = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
exports.SelectedOptionHeadingWrapper = SelectedOptionHeadingWrapper;
const SelectedOptionHeading = (0, styled_components_1.default)(Typography_1.H6) `
  text-transform: uppercase;
  color: ${(props) => (props.$isSelected ? colors_1.gray400 : colors_1.gray600)};
  opacity: ${(props) => props.$isSelected && 0.5};
`;
exports.SelectedOptionHeading = SelectedOptionHeading;
const SelectedOption = (0, styled_components_1.default)(Typography_1.H4) `
  color: ${(props) => (props.$isSelected ? colors_1.gray400 : colors_1.gray300)};
`;
exports.SelectedOption = SelectedOption;
const ChevronIcon = styled_components_1.default.img `
  width: 1rem;
  height: 2rem;
  background-color: ${(props) => (props.$isActive ? colors_1.acBlue : colors_1.gray300)};
  mask: url(${(props) => props.$src});
  mask-size: 100% 100%;
`;
exports.ChevronIcon = ChevronIcon;
const FiltersOptionsWrapper = (0, styled_components_1.default)(SessionFilters) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.6rem;
  margin: 0;
  z-index: 3;
  left: 105%;
  top: 1.6rem;
  border-top-left-radius: 0;
  width: fit-content;
`;
exports.FiltersOptionsWrapper = FiltersOptionsWrapper;
const FiltersOptionHeading = styled_components_1.default.span `
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  color: ${colors_1.gray300};
`;
exports.FiltersOptionHeading = FiltersOptionHeading;
const FiltersOptionButton = (0, styled_components_1.default)(Button_style_1.Button) `
  gap: 0;
  padding: 0.5rem 0.8rem;
  margin-bottom: 0.8rem;
  min-width: 12rem;
  height: 3rem;
  justify-content: flex-start;
  color: ${(props) => (props.$isSelected ? colors_1.acBlue : colors_1.gray300)};
  border: 1px solid ${colors_1.gray500};
  border-color: ${(props) => props.$isSelected && colors_1.lightBlue};
  font-size: 1.2rem;
  text-transform: capitalize;
  white-space: nowrap;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
exports.FiltersOptionButton = FiltersOptionButton;
const SeeMoreButton = styled_components_1.default.button `
  display: flex;
  align-items: center;
  align-self: end;
  border: none;
  background-color: transparent;
`;
exports.SeeMoreButton = SeeMoreButton;
const SeeMoreSpan = styled_components_1.default.span `
  font-size: 1.2rem;
  color: ${colors_1.gray300};
  margin-right: 0.2rem;

  &::first-letter {
    text-transform: uppercase;
  }
`;
exports.SeeMoreSpan = SeeMoreSpan;
const BasicParameterWrapper = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
`;
exports.BasicParameterWrapper = BasicParameterWrapper;
const ChevronBackButton = styled_components_1.default.button `
  border: none;
  background-color: transparent;
  margin-top: 0.3rem;
`;
exports.ChevronBackButton = ChevronBackButton;
const Description = styled_components_1.default.p `
  font-size: 1.2rem;
  line-height: 1.45rem;
  opacity: 50%;
`;
exports.Description = Description;
const ButtonsWrapper = styled_components_1.default.div `
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 1.6rem;
  height: 4.2rem;
`;
exports.ButtonsWrapper = ButtonsWrapper;
const BackButton = (0, styled_components_1.default)(Button_style_1.Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  background-color: ${colors_1.gray100};
  color: ${colors_1.gray400};
  margin-right: 1rem;
  flex-grow: 2;
`;
exports.BackButton = BackButton;
const MinorShowSessionsButton = (0, styled_components_1.default)(ShowSessionsButton) `
  width: fit-content;
  flex-grow: 3;
`;
exports.MinorShowSessionsButton = MinorShowSessionsButton;
const BasicParameterButtonsWrapper = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;
exports.BasicParameterButtonsWrapper = BasicParameterButtonsWrapper;
const BasicParameterButton = styled_components_1.default.button `
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  padding-right: 1rem;
`;
exports.BasicParameterButton = BasicParameterButton;
const ButtonSpan = (0, styled_components_1.default)(HeaderTitle) `
  color: ${(props) => (props.$isActive ? colors_1.acBlue : colors_1.gray400)};
  margin: 0;
`;
exports.ButtonSpan = ButtonSpan;
const GrayButton = (0, styled_components_1.default)(Button_style_1.Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  background-color: ${colors_1.gray100};
  color: ${colors_1.gray400};
  width: 97%;
  align-self: center;
`;
exports.GrayButton = GrayButton;
const CustomParameterWrapper = styled_components_1.default.div ``;
exports.CustomParameterWrapper = CustomParameterWrapper;
const CustomParametersListWrapper = styled_components_1.default.div `
  height: calc(100% - (10.5rem + 4.2rem + 1.6rem));

  @media (${media_1.media.desktop}) {
    height: auto;
  }
`;
exports.CustomParametersListWrapper = CustomParametersListWrapper;
const CustomParameterSearch = styled_components_1.default.div `
  display: flex;
`;
exports.CustomParameterSearch = CustomParameterSearch;
const CustomParametersInput = (0, styled_components_1.default)(LocationSearch_style_1.SearchInput) `
  height: 3.2rem;
  width: 100%;
  background-size: 2.2rem;
  font-size: 1.6rem;
  color: ${colors_1.gray300};
  padding: 1.8rem 4rem;
  margin-top: 0.8rem;
  background-position-x: 0.7rem;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid ${colors_1.gray300};

  @media (${media_1.media.desktop}) {
    height: 2.4rem;
    width: 21rem;
    background-size: 0.9rem;
    font-size: 1rem;
    padding: 0.5rem 2.4rem;
    background-position-x: 0.7rem;
    border: 1px solid ${colors_1.gray500};
  }
`;
exports.CustomParametersInput = CustomParametersInput;
const Hr = styled_components_1.default.hr `
  display: block;
  margin: 2rem 3rem;
  border: 1px solid ${colors_1.gray200};
`;
exports.Hr = Hr;
const CustomParameterList = styled_components_1.default.ul `
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.5rem;
  overflow-y: auto;
  padding-top: 1.8rem;
  height: 100%;

  @media (${media_1.media.desktop}) {
    grid-template-columns: 1fr 1fr;
    max-height: 40rem;
    margin-top: 0.8rem;
    padding-top: 0;
  }
`;
exports.CustomParameterList = CustomParameterList;
const CustomParameterItem = styled_components_1.default.li `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.2rem;
  padding-right: 1rem;
`;
exports.CustomParameterItem = CustomParameterItem;
const CustomParameter = styled_components_1.default.button `
  border: none;
  background-color: transparent;
  color: ${(props) => (props.$isActive ? colors_1.acBlue : colors_1.gray400)};
  font-size: 1.6rem;
  text-align: left;
  white-space: nowrap;
  margin-bottom: 0.8rem;
  @media (${media_1.media.desktop}) {
    font-size: 1.2rem;
    color: ${colors_1.gray300};
  }
`;
exports.CustomParameter = CustomParameter;
const DesktopCustomParameters = styled_components_1.default.div `
  display: none;
  @media (${media_1.media.desktop}) {
    display: flex;
  }
`;
exports.DesktopCustomParameters = DesktopCustomParameters;
const MobileCustomParameters = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  @media (${media_1.media.desktop}) {
    display: none;
  }
`;
exports.MobileCustomParameters = MobileCustomParameters;
const IndoorToggleHeading = styled_components_1.default.span `
  font-size: 1rem;
  text-transform: uppercase;
  color: ${colors_1.gray600};
  margin-top: 1.6rem;
`;
exports.IndoorToggleHeading = IndoorToggleHeading;
const IndoorFilterWrapper = styled_components_1.default.div `
  width: 100%;
`;
exports.IndoorFilterWrapper = IndoorFilterWrapper;
const IndoorToggleWrapper = (0, styled_components_1.default)(SessionToggleWrapper) `
  border-radius: ${(props) => props.$isIndoor && "10px 10px 0 0"};
  margin-top: 0.5rem;
`;
exports.IndoorToggleWrapper = IndoorToggleWrapper;
const IndoorTab = (0, styled_components_1.default)(Tab) `
  &:first-child {
    border-radius: ${(props) => props.$isIndoor && "10px 0 0 0"};
  }

  &:nth-child(2) {
    border-radius: ${(props) => props.$isIndoor && "0 10px 0px 0"};
  }
`;
exports.IndoorTab = IndoorTab;
const IndoorToggleInfoWrapper = styled_components_1.default.div `
  padding: 0.8rem 1rem;
  border-radius: 0 0 10px 10px;
  background-color: ${colors_1.lightBlueInfo};
`;
exports.IndoorToggleInfoWrapper = IndoorToggleInfoWrapper;
const IndoorToggleInfoText = styled_components_1.default.span `
  font-size: 1.2rem;
  display: block;

  &:nth-child(2) {
    margin-top: 0.5rem;
  }
`;
exports.IndoorToggleInfoText = IndoorToggleInfoText;
const YearPickerWrapper = styled_components_1.default.div `
  border: 1px solid ${colors_1.gray200};
  border-radius: 0.5rem;
  color: ${colors_1.gray600};
  display: grid;
  font-size: 1rem;
  grid-gap: 0.4rem;
  letter-spacing: 0.01em;
  overflow: hidden;
  padding: 1.1rem 1.6rem;
  text-transform: uppercase;
  width: 100%;
`;
exports.YearPickerWrapper = YearPickerWrapper;
const DormantYearPickerWrapper = styled_components_1.default.div `
  border-top: 1px solid ${colors_1.gray200};
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0.8rem 0 0 0;
  font-size: 1rem;
  display: grid;
  grid-gap: 0.4rem;
`;
exports.DormantYearPickerWrapper = DormantYearPickerWrapper;
