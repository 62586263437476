"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelapseMarkers = void 0;
const react_google_maps_1 = require("@vis.gl/react-google-maps");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const thresholdSlice_1 = require("../../../store/thresholdSlice");
const mapParamsHandler_1 = require("../../../utils/mapParamsHandler");
const thresholdColors_1 = require("../../../utils/thresholdColors");
const createMarkerIcon_1 = require("./createMarkerIcon");
const calculateZIndex = (sessions) => {
    return sessions === 1
        ? Number(google.maps.Marker.MAX_ZINDEX + 2)
        : Number(google.maps.Marker.MAX_ZINDEX + 1);
};
const TimelapseMarkers = ({ sessions }) => {
    const thresholds = (0, react_redux_1.useSelector)(thresholdSlice_1.selectThresholds);
    const { unitSymbol } = (0, mapParamsHandler_1.useMapParams)();
    const map = (0, react_google_maps_1.useMap)();
    const markersRef = (0, react_1.useRef)(new Map());
    (0, react_1.useEffect)(() => {
        if (!map)
            return;
        const sessionsMap = new Map();
        sessions.forEach((session) => {
            const key = `${session.latitude}-${session.longitude}`;
            sessionsMap.set(key, session);
        });
        // Update existing markers and create new ones if necessary
        sessionsMap.forEach((session, key) => {
            let marker = markersRef.current.get(key);
            if (marker) {
                // Update existing marker
                const position = {
                    lat: session.latitude,
                    lng: session.longitude,
                };
                const color = (0, thresholdColors_1.getColorForValue)(thresholds, session.value);
                let icon;
                if (session.sessions === 1) {
                    const valueText = `${Math.round(session.value)} ${unitSymbol}`;
                    icon = (0, createMarkerIcon_1.createMarkerIcon)(color, valueText, false, false);
                }
                else {
                    icon = (0, createMarkerIcon_1.createClusterIcon)(color, false);
                }
                marker.setPosition(position);
                marker.setIcon(icon);
                marker.setZIndex(calculateZIndex(session.sessions));
                marker.setTitle(session.value.toString());
            }
            else {
                // Create new marker
                const position = {
                    lat: session.latitude,
                    lng: session.longitude,
                };
                const color = (0, thresholdColors_1.getColorForValue)(thresholds, session.value);
                let icon;
                if (session.sessions === 1) {
                    const valueText = `${Math.round(session.value)} ${unitSymbol}`;
                    icon = (0, createMarkerIcon_1.createMarkerIcon)(color, valueText, false, false);
                }
                else {
                    icon = (0, createMarkerIcon_1.createClusterIcon)(color, false);
                }
                marker = new google.maps.Marker({
                    position,
                    map,
                    zIndex: calculateZIndex(session.sessions),
                    title: session.value.toString(),
                    icon,
                });
                markersRef.current.set(key, marker);
            }
        });
        // Remove markers that are no longer in the sessions array
        markersRef.current.forEach((marker, key) => {
            if (!sessionsMap.has(key)) {
                marker.setMap(null);
                markersRef.current.delete(key);
            }
        });
    }, [map, sessions, thresholds, unitSymbol]);
    // Cleanup markers when component unmounts
    (0, react_1.useEffect)(() => {
        return () => {
            markersRef.current.forEach((marker) => {
                marker.setMap(null);
            });
            markersRef.current.clear();
        };
    }, []);
    return null;
};
exports.TimelapseMarkers = TimelapseMarkers;
