"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetTags = exports.setFixedSessionsType = exports.setCustomSensorsModalOpen = exports.setBasicSensorsModalOpen = exports.setCustomParametersModalOpen = exports.setBasicParametersModalOpen = exports.selectIsUsernamesInputFetching = exports.selectIsTagsInputFetching = exports.selectIsDormantSessionsType = exports.selectFixedSessionsType = exports.selectCustomSensorsModalOpen = exports.selectBasicSensorsModalOpen = exports.selectCustomParametersModalOpen = exports.selectBasicParametersModalOpen = exports.selectTags = exports.selectUsernames = exports.fetchTags = exports.fetchUsernames = exports.FixedSessionsTypes = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
var FixedSessionsTypes;
(function (FixedSessionsTypes) {
    FixedSessionsTypes["ACTIVE"] = "active";
    FixedSessionsTypes["DORMANT"] = "dormant";
})(FixedSessionsTypes || (exports.FixedSessionsTypes = FixedSessionsTypes = {}));
const initialState = {
    usernames: [],
    tags: [],
    fetchUsernamesStatus: "IDLE" /* StatusEnum.Idle */,
    fetchTagsStatus: "IDLE" /* StatusEnum.Idle */,
    usernamesError: null,
    tagsError: null,
    basicParametersModalOpen: false,
    customParametersModalOpen: false,
    basicSensorsModalOpen: false,
    customSensorsModalOpen: false,
    fixedSessionsType: FixedSessionsTypes.ACTIVE,
};
exports.fetchUsernames = (0, toolkit_1.createAsyncThunk)("autocomplete/usernames", (params, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchUsernames(params));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchUsernames",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchUsernames(params),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
exports.fetchTags = (0, toolkit_1.createAsyncThunk)("autocomplete/tags", (params, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchTags(params));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchTags",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchTags(params),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
const sessionFilterSlice = (0, toolkit_1.createSlice)({
    name: "sessionFilter",
    initialState,
    reducers: {
        setBasicParametersModalOpen: (state, action) => {
            state.basicParametersModalOpen = action.payload;
        },
        setCustomParametersModalOpen: (state, action) => {
            state.customParametersModalOpen = action.payload;
        },
        setBasicSensorsModalOpen: (state, action) => {
            state.basicSensorsModalOpen = action.payload;
        },
        setCustomSensorsModalOpen: (state, action) => {
            state.customSensorsModalOpen = action.payload;
        },
        setFixedSessionsType: (state, action) => {
            state.fixedSessionsType = action.payload;
        },
        resetTags: (state) => {
            state.tags = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchUsernames.pending, (state) => {
            state.fetchUsernamesStatus = "PENDING" /* StatusEnum.Pending */;
            state.usernamesError = null;
        })
            .addCase(exports.fetchUsernames.fulfilled, (state, action) => {
            state.fetchUsernamesStatus = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.usernames = action.payload;
            state.usernamesError = null;
        })
            .addCase(exports.fetchUsernames.rejected, (state, action) => {
            state.fetchUsernamesStatus = "REJECTED" /* StatusEnum.Rejected */;
            state.usernames = [];
            state.usernamesError = action.payload || {
                message: "Unknown error occurred",
            };
        })
            .addCase(exports.fetchTags.pending, (state) => {
            state.fetchTagsStatus = "PENDING" /* StatusEnum.Pending */;
            state.tagsError = null;
        })
            .addCase(exports.fetchTags.fulfilled, (state, action) => {
            state.fetchTagsStatus = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.tags = action.payload;
            state.tagsError = null;
        })
            .addCase(exports.fetchTags.rejected, (state, action) => {
            state.fetchTagsStatus = "REJECTED" /* StatusEnum.Rejected */;
            state.tags = [];
            state.tagsError = action.payload || {
                message: "Unknown error occurred",
            };
        });
    },
});
const selectUsernames = (state) => state.sessionFilter.usernames;
exports.selectUsernames = selectUsernames;
const selectTags = (state) => state.sessionFilter.tags;
exports.selectTags = selectTags;
const selectBasicParametersModalOpen = (state) => state.sessionFilter.basicParametersModalOpen;
exports.selectBasicParametersModalOpen = selectBasicParametersModalOpen;
const selectCustomParametersModalOpen = (state) => state.sessionFilter.customParametersModalOpen;
exports.selectCustomParametersModalOpen = selectCustomParametersModalOpen;
const selectBasicSensorsModalOpen = (state) => state.sessionFilter.basicSensorsModalOpen;
exports.selectBasicSensorsModalOpen = selectBasicSensorsModalOpen;
const selectCustomSensorsModalOpen = (state) => state.sessionFilter.customSensorsModalOpen;
exports.selectCustomSensorsModalOpen = selectCustomSensorsModalOpen;
const selectFixedSessionsType = (state) => state.sessionFilter.fixedSessionsType;
exports.selectFixedSessionsType = selectFixedSessionsType;
const selectIsDormantSessionsType = (state) => state.sessionFilter.fixedSessionsType === FixedSessionsTypes.DORMANT;
exports.selectIsDormantSessionsType = selectIsDormantSessionsType;
const selectIsTagsInputFetching = (state) => state.sessionFilter.fetchTagsStatus === "PENDING" /* StatusEnum.Pending */;
exports.selectIsTagsInputFetching = selectIsTagsInputFetching;
const selectIsUsernamesInputFetching = (state) => state.sessionFilter.fetchUsernamesStatus === "PENDING" /* StatusEnum.Pending */;
exports.selectIsUsernamesInputFetching = selectIsUsernamesInputFetching;
_a = sessionFilterSlice.actions, exports.setBasicParametersModalOpen = _a.setBasicParametersModalOpen, exports.setCustomParametersModalOpen = _a.setCustomParametersModalOpen, exports.setBasicSensorsModalOpen = _a.setBasicSensorsModalOpen, exports.setCustomSensorsModalOpen = _a.setCustomSensorsModalOpen, exports.setFixedSessionsType = _a.setFixedSessionsType, exports.resetTags = _a.resetTags;
exports.default = sessionFilterSlice.reducer;
